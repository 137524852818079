// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'
import store from '@/store'
let moYeMian = "";
// 前端路由表 (基于动态)
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),




  // result
  ResultSuccess: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
  ResultFail: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),

  // exception
  Exception403: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
  Exception404: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  Exception500: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),

  'pinduoduo': () => import('@/views/user/auth/pinduoduo')
  // 'TestWork': () => import(/* webpackChunkName: "TestWork" */ '@/views/dashboard/TestWork')
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/user/manage/list',
  meta: {
    title: '首页'
  },
  children: []
}

const pinduoduo = {
  key: '/user/auth/pinduoduo',
  name: '/user/auth/pinduoduo',
  path: '/user/auth/pinduoduo',
  component: 'pinduoduo'
}

// export const generatorStaticRouter = () => {

// }

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = token => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav(token)
      .then(res => {
        const { appAutoVoList } = res.data[0]
        const { moduleVoList } = res.data[0]
        const { routeModuleList } = res.data[0]
        const { dictionaryListVos } = res.data[0]
        store.dispatch('setMenuList', moduleVoList)
        store.dispatch('setRoutingMatching',routeModuleList)
        store.dispatch('setFrontProject',dictionaryListVos)
        let productList = [];

        if(res.data){
          res.data.forEach( (rd) => {
            let product  = {'id':rd.id,'name':rd.name,'code':rd.code}
            productList.push(product)
          })
          store.dispatch("setProductList", productList);
        }

        let functions = []
        moduleVoToTree(moduleVoList,functions)
        store.dispatch('setFunctions', functions)
        const menuNav = []
        const childrenNav = []

        let routing_matching_map = store.getters.routing_matching;

        appAutoVoList.forEach( (appAu)=>{
          if(moYeMian == "" && appAu.path != ""
            && appAu.redirect != undefined && appAu.redirect != ""){
            if(routing_matching_map.has(appAu.path) && appAu.path.indexOf("console") == -1){
              let routeMenu = routing_matching_map.get(appAu.path)
              if(routeMenu.code == 'user'){
                moYeMian = appAu.redirect;
              }
            }
          }
        })

        //      后端数据, 根级树数组,  根级 PID
        listToTree(appAutoVoList, childrenNav, 0)
        rootRouter.redirect = moYeMian;
        rootRouter.children = childrenNav
        menuNav.push(pinduoduo)
        menuNav.push(rootRouter)
        rootRouter.redirect = moYeMian;
        const routers = generator(menuNav)
        routers.push(notFoundRouter)

        resolve(routers)
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  let pathSet = new Set();
  let routing_matching_map = store.getters.routing_matching;
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } = item.meta || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      //   path: item.path || `${(parent && parent.path) || ''}/${item.key}`,
      // path: item.path,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component || item.key] || (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        keepAlive: false,
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }

    if(item.path != "" && item.path != undefined){
      currentRouter.path = item.path
    }else{
      currentRouter.path = item.path || `${(parent && parent.path) || ''}/${item.key}`
    }

    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (parseFloat(item.parentId) === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, parseFloat(item.id))
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}

/**
 * 格式化uri post
 */
const moduleVoToTree = (moduleVoList,functions)=> {
  moduleVoList.forEach(moduleVo => {
    if(moduleVo.childList && moduleVo.childList.length > 0){
      moduleVoToTree(moduleVo.childList,functions)
    }else{
      if(moduleVo.functionVoList && moduleVo.functionVoList.length > 0){
        moduleVo.functionVoList.forEach(fun => {
          functions.push(fun)
        })
      }
    }
  })
}
